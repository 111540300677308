@import "../../../resources/sass/variables.scss";
@import "../../../resources/sass/breakpoints.scss";
.how-it-works {
  background-color: #f2f5f8;

  h4 {
    color: #041e55;
  }

  @include media-breakpoint-down(md) {
    .Navigator__content {
      padding-left: $spacing-base;
    }
  }

  @include media-breakpoint-down(md) {
    background-color: white;

    h4 {
      font-size: 1.5rem !important;
      // text-align: center;
      padding-left: $spacing-base;
      margin-bottom: 0;
      width: 75%;
    }
  }

  .how_content {
    padding-bottom: $spacing-base !important;
  }
}

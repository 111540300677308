@import "../../../resources/sass/variables.scss";
@import "../../../resources/sass/breakpoints.scss";

.dropzone {
  margin-top: 40px;
  background: #f2f5f8;
  backdrop-filter: blur(15px);
  border-radius: 16px;

  @include media-breakpoint-down(md) {
    padding: $spacing-base/2;
    margin: $spacing-base/4;
  }

  &__body {
    display: grid;
    grid-template-columns: 1.5fr 0.3px 2fr;

    input[type="text"] {
      width: 400px;
    }

    padding: $spacing-base * 1.5 $spacing-base * 1.5;
    &-line {
      width: 2px;
      // height: 100px; /* ajusta la altura según tus necesidades */
      background-color: #cbcfde;
    }
    @include media-breakpoint-down(md) {
      padding-top: $spacing-base/2;
      padding-left: 0;
      padding-right: 0;
    }
    &-files {
      background-color: white;
      width: 100%;
      border-width: 1px;
      border-style: solid;
      border-color: #0096eb;
      border-radius: 8px 0px 0px;
      padding: 5px;
      display: flex;
      gap: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include media-breakpoint-down(md) {
        width: initial;
      }
    }
    input {
      @include media-breakpoint-down(md) {
        font-size: 1rem !important;
        padding: 8px;
        border-radius: 8px !important;
      }
    }
    button {
      @include media-breakpoint-down(md) {
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
        border-radius: 8px !important;
        font-size: 20px;
      }
    }
    &-error {
      border: 1px solid #ffccc7;
    }
  }

  h2 {
    font-size: 1rem;
    // line-height: 25px;
    letter-spacing: 0.05em;
    color: #0096eb;
    @include media-breakpoint-down(md) {
      margin-bottom: 5px;
    }
  }

  ul {
    // gap: 25px;
    list-style: none;
    @include media-breakpoint-down(md) {
      flex-direction: column;
      gap: 15px;

      li {
        margin: auto;
        text-align: center;
        @include media-breakpoint-down(md) {
          display: flex;
          margin: initial;
          text-align: left;
          gap: 10px;
          img {
            display: none;
          }
        }
      }
    }

    .item-icon {
      svg {
        width: 40px;
        height: 40px;
      }
      @include media-breakpoint-down(md) {
        margin-top: auto;
        margin-bottom: auto;
        svg {
          width: 48px;
          height: 48px;
        }
      }
    }
  }

  p {
    color: #495057;
    font-weight: lighter;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  button {
    span {
      font-size: 1rem;
      font-family: $font-medium !important;
    }
  }

  .input-container {
    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .custom-upload {
    position: relative;
  }

  .ant-progress {
    margin-top: 8px;
  }
  .ant-progress-text {
    display: none;
  }

  .ant-progress-line {
    position: absolute !important;
    height: 100% !important;
    // top: 0 !important;
  }

  .ant-progress-outer,
  .ant-progress-bg,
  .ant-progress-inner {
    height: 100% !important;
    border-radius: 8px 0 0 8px !important;
  }

  .ant-progress-outer {
    padding-inline-end: 0 !important;
  }

  .ant-progress-bg {
    background-color: #38adef !important;
  }

  .ant-upload-drag {
    border: none;
    background: initial !important;
  }

  .ant-input {
    color: #adb5bd;
    font-size: 1rem;
  }
  .ant-btn {
    height: initial !important;
    border-radius: 6px 6px 6px 0;
  }

  .ant-alert {
    border-radius: 0 0 8px 8px;
    padding-block: 10px;
    @include media-breakpoint-down(md) {
      margin-top: 10px;
      border-radius: 8px;
    }
    .ant-alert-message {
      text-transform: capitalize;
    }
  }
  .checkbox_disabled {
    background-color: #fff2f0;
    border: 1px solid #ffccc7;
    border-radius: 0 0 8px 8px;
    padding-block: 10px;
    align-items: flex-start;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    .ant-checkbox-inner {
      border: 1px solid red;
    }
  }

  &.v2 {
    .custom-upload {
      padding-top: $spacing-base/2;
    }

    .ant-tabs-nav-list {
      justify-content: space-around;
      width: 100%;
    }

    .dropzone__body {
      padding-top: 0;

      &-upload {
        margin: 0 20px;
      }
    }

    .dropzone__actions {
      // display: flex;
      // justify-content: space-between;

      p {
        font-size: 0.8rem;
      }
    }
    .ant-tabs-tab {
      padding-bottom: 5px;
      p {
        font-size: 1rem;
        color: $telefonica-blue !important;
      }
      &.ant-tabs-tab-active {
        p {
          font-family: $font-regular;
        }
      }
    }

    :where(.css-dev-only-do-not-override-nllxry).ant-tabs .ant-tabs-tab {
      padding: 5px 40px !important;
      padding-bottom: 10px !important;
    }

    .item-icon > div {
      display: flex;
      margin-left: 12px;
    }

    :where(.css-dev-only-do-not-override-nllxry).ant-tabs .ant-tabs-ink-bar {
      background-color: $telefonica-blue;
      height: 2px;
    }
  }

  .motors svg {
    fill: $telefonica-blue;
  }
}

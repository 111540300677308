.w-100 {
  width: 100% !important;
}
.w-90 {
  width: 90% !important;
}

.w-30 {
  width: 30% !important;
}
.w-40 {
  width: 40% !important;
}

.w-70 {
  width: 70% !important;
}
.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}
.w-60 {
  width: 60% !important;
}

.w-50 {
  width: 50% !important;
}

.w-25 {
  width: 25% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-250 {
  width: 250px;
}

.ml-auto {
  margin-left: auto;
}
.mt-auto {
  margin-top: auto !important;
}
.mr-auto {
  margin-right: auto;
}
.mb-auto {
  margin-bottom: auto;
}

.mt-0 {
  margin-top: 0.5rem;
}

.mr-0 {
  margin-right: 0.5rem;
}

.mr-1 {
  margin-right: 1rem;
}

.ml-0 {
  margin-left: 0.5rem;
}

.ml-1 {
  margin-left: 1rem;
}

.ml-2 {
  margin-left: 2rem;
}

.ml-3 {
  margin-left: 3rem;
}

.mb-0 {
  margin-bottom: 0.5rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.m-auto {
  margin: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}

.mt-3 {
  margin-top: 3rem;
}

.p-1 {
  padding: 1rem;
}

.p-2 {
  padding: 2rem;
}

.pt-1 {
  padding-top: 1rem;
}

.pt-2 {
  padding-top: 2rem;
}

.pt-3 {
  padding-top: 3rem;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0.5rem;
}

.pb-1 {
  padding-bottom: 1rem;
}

.pb-2 {
  padding-bottom: 2rem;
}

.pb-3 {
  padding-bottom: 3rem;
}

.pl-0 {
  padding-left: 0.5rem;
}

.pl-1 {
  padding-left: 1rem;
}

.pl-2 {
  padding-left: 2rem;
}

.pl-3 {
  padding-left: 3rem;
}

.pr-0 {
  padding-right: 0.5rem;
}

.pr-1 {
  padding-right: 1rem;
}

.pr-2 {
  padding-right: 2rem;
}

.pr-3 {
  padding-right: 3rem;
}

.font-small {
  font-size: 1rem;
}

.font-medium {
  font-size: 1.5rem;
}

.font-large {
  font-size: 2rem;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-black {
  color: black;
}

.text-blue {
  color: #019df4 !important;
}

.full-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.space-evenly {
  display: flex;
  justify-content: space-evenly;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.g-10 {
  gap: 10px;
}

.g-20 {
  gap: 20px;
}

.g-15 {
  gap: 15px;
}

.d-hover {
  &:hover {
    cursor: pointer;
  }
}

.f-1 {
  flex: 1;
}

.f-2 {
  flex: 2;
}

/* effect a */

.input-animated {
  position: relative;
}
.input-animated:after {
  background:rgba(166, 172, 168, 0.151);
  content: '';
  display: block;
  width: 80%;
  height: 100%;
  position: absolute;
  top: 0%;
  left: 13%;
  border-radius: 4px;
  animation: pulse 200ms forwards;
}

@keyframes pulse {
  0% {
    transform:scale3d(0.3,0.3,1);
  }
  25%,50% {
    opacity:0.75;
  }
  100% {
    opacity:0;
    transform:scale3d(1.2,1.2,1);
  }
}
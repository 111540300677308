@import "../../../resources/sass/variables.scss";
@import "../../../resources/sass/breakpoints.scss";
.footer {
  position: relative;
  margin: auto;

  align-items: center;
  padding: $spacing-base $spacing-base * 2;
  background-color: #ffffff;
  color: #2b3447;
  font-size: 14px;

  @include media-breakpoint-down(md) {
    padding: $spacing-base $spacing-base !important;
  }

  &__info {
    color: #2b3447 !important;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: row;
    border-top: 0.5px white inset;
    padding-top: $spacing-base/2;
    a {
      color: #2b3447 !important;
      text-decoration: none;
      margin-right: 2rem;
      font-size: 16px;
    }

    @include media-breakpoint-down(md) {
      flex-direction: column;
      gap: 5px;
    }
  }

  &__mail {
    position: absolute;
    bottom: -5px;
    right: 0;
  }
}

@import "../../../resources/sass/variables.scss";
@import "../../../resources/sass/breakpoints.scss";
.ForgotPassword__modal {
  .ant-modal-title {
    font-family: $font-semibold;
    font-size: 1.2rem;
  }
  h3,
  p {
    color: black;
  }
  &-body {
    height: 470px;
    img {
      width: 100%;
    }
    h3 {
      font-size: 1.1rem;
      font-family: $font-regular;
      margin-top: $spacing-base/2;
      margin-bottom: $spacing-base/2;
    }
    p {
      font-family: $font-light;
      margin-bottom: $spacing-base/2;
    }
    a {
      color: $telefonica-blue;
      display: flex;
      justify-content: center;
    }
  }
  &-step {
    border: 2px solid red;
  }

  &-error {
    color: red !important;
  }

  .ant-modal-footer {
    text-align: center;
    .ant-btn-default {
      background-color: transparent;
      border: none;
    }
    .ant-btn-primary {
      border-radius: 30px;
    }
  }

  .ant-steps {
    display: none;
  }
}

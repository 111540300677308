@import "variables";
@import "breakpoints";
@import "animations";
@import "util";
@import "typography";
@import "antd";
@import "report";
$breakpoints: (
  s: 0,
  m: 576px,
  l: 768px,
  lg: 992px,
  xl: 1200px,
);

body {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - #{$header-height});
  color: $text-color;
  font-size: $normal-font-size;
  line-height: $body-line-height;
  background: white;
  margin: 0;
  padding: 0;

  @include media-breakpoint-down(md) {
    background-color: #f2f3f4;
  }
}

.max-width {
  max-width: $max-width;
  padding: 0;
  margin: auto;
  // border: 0.5px solid red;
}

.max-padding {
  padding-left: calc(((100vw - #{$max-width}) / 2) - 5px);
  padding-right: calc(((100vw - #{$max-width}) / 2) - 5px);
}

header.header {
  @extend .max-width;
}

* {
  font-family: $font-regular;
}

.content-wrapper {
  @include media-breakpoint-down(md) {
    width: calc(100% - 20px) !important;
  }
}

.btn-icon button {
  @extend .all-center;
}

main {
  min-height: calc(100vh - (64px + 240px));
  background: #202735 !important;
  margin: $spacing-base 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: $spacing-base * 2;
  // padding-bottom: $spacing-base * 2 !important;
  @include media-breakpoint-down(md) {
    margin: 0 0;
    // background-color: white;
  }
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.img-round {
  border-radius: 50%;
}

a {
  color: $primary-color;
  &:hover {
    cursor: pointer;
  }
}

p {
  margin-bottom: 0;
  margin-top: 0;
  color: $text-color;
  font-family: $font-light;
  @include media-breakpoint-down(md) {
    font-size: 1rem !important;
  }
}

ul,
li {
  font-family: $font-regular;
}

.icon-navbar {
  svg {
    width: 25px;
    height: 25px;
  }
}

.icon-small svg {
  width: 12px;
  height: 12px;
}

.icon-table svg {
  width: 16px;
  height: 16px;
}

.icon svg {
  width: 16px;
  height: 16px;
  &:hover {
    cursor: pointer;
  }
}

.icon-semi-medium svg {
  width: 20px;
  height: 20px;
  &:hover {
    cursor: pointer;
  }
}

.icon-medium svg {
  width: 24px;
  height: 24px;
}

.icon-large svg {
  width: 30px;
  height: 30px;
}

.icon-extralarge {
  width: 48px;
  height: 48px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  color: $text-color;
  line-height: $title-line-height;
}

section {
  padding-top: ($spacing-base * 3);
  padding-bottom: ($spacing-base * 3);
  position: relative;
  background: transparent;
  margin-bottom: 0;
  @include media-breakpoint-down(md) {
    padding-top: $spacing-base;
    padding-bottom: $spacing-base;
  }
}

h1 {
  font-size: $h1-font-size;
  font-family: $font-light;
  font-weight: 400;
  color: $text-color;
  @include media-breakpoint-down(md) {
    font-size: $h1-font-size/2 !important;
  }

  b {
    font-family: $font-bold;
  }
}

h2 {
  font-size: $h2-font-size;
  font-family: $font-medium;
  font-weight: 400;
}

h3 {
  font-size: $h3-font-size;
  @include media-breakpoint-down(md) {
    font-size: $h3-font-size * 0.7 !important;
  }
}

h4 {
  font-size: $h4-font-size;
  @include media-breakpoint-down(md) {
    font-size: $h4-font-size * 0.6 !important;
  }
}

b {
  font-family: $font-bold;
}

button {
  font-family: $font-regular;
}

ul {
  list-style: circle;
  padding-left: 0;
}

ul.link {
  li {
    text-decoration: underline;
    color: $primary-color;
  }
}

.link-button {
  color: #4343ff;
  font-family: $font-medium;
}

.link {
  text-decoration: underline;
  color: $primary-color;
}

.section-container {
  margin-bottom: $section-spacing;
}

.content-wrapper {
  width: calc(100% - ($spacing-base * 4));
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.container-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.disabled:hover {
  cursor: not-allowed;
  pointer-events: all !important;
}

.pointer:hover {
  cursor: pointer;
}

.d-relative {
  position: relative;
}

.d-absolute {
  position: absolute;
}

.ant-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.s-pt-6 {
  padding-top: 3rem;
}

.p-relative {
  position: relative;
  display: inline-block;
}

.all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.font-bold {
  font-family: $font-bold;
}

.font-light {
  font-family: $font-light;
}

.font-regular {
  font-family: $font-regular;
}

.font-medium-2 {
  font-family: $font-medium;
}

.terms_condition {
  display: flex;
  text-align: center;
  justify-content: center;
  p {
    color: black;
  }
}

.from-lg {
  display: block;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.to-md {
  display: none;
  @include media-breakpoint-down(md) {
    display: block;
  }
}

.hash {
  overflow-wrap: break-word;
  max-width: 180px;
}

@import "../../../resources/sass/variables.scss";
@import "../../../resources/sass/breakpoints.scss";
$user-blue: #3232b9;
.UserPanel {
  background-color: white;
  padding: 25px;
  border-radius: 10px;
  width: 300px;
  p {
    color: black;
  }
  &__header {
    display: flex;
    text-align: center;
    justify-content: center;
    align-content: center;
    padding-bottom: 10px;
    border-bottom: 0.5px solid grey;
    p {
      font-size: 1.2rem;
    }
  }
  &__body {
    button {
      color: $user-blue;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px 5px;
    &-label {
      background-color: #22228027;
      border-radius: 8px;
      // border: 1px solid black;
      padding: 5px;
      p,
      small {
        color: $user-blue;
      }
      &.item-red {
        background-color: #ff4d4f30;
        p,
        small {
          color: #ff4d4f;
        }
      }
      p {
        font-size: 0.6rem;
      }
      small {
        font-size: 0.8rem;
      }
    }
  }
  &__footer {
    text-align: center;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    button.ant-btn-primary {
      background-color: $user-blue !important;
      width: 200px;
      margin: auto;
      color: white;
    }
    button.ant-btn-link {
      width: 200px;
    }
    button {
      color: $user-blue;
    }
    &-latch {
      border: 1px solid $user-blue;
      display: flex;
      border-radius: 10px;
      justify-content: center;
      &:hover {
        color: $user-blue !important;
        background-color: inherit;
      }
    }
  }
}

@import "../../../../../resources/sass/variables.scss";
@import "../../../../../resources/sass/breakpoints.scss";
.ResultItem {
  background-color: white;
  padding: $spacing-base/2 0;
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  align-items: center;
  // border-bottom: 0.5px solid #c1c1c1;
  font-size: 0.9rem;

  &__progress {
    display: flex;
    // align-items: center;
    gap: 20px;
    p {
      font-size: 0.8rem;
      max-width: max-content !important;
    }
  }
  &__tags {
    margin-top: auto;
    .ant-tag {
      font-family: $font-bold;
      font-size: 0.7rem;
      color: #4343ff;
      border: 1px solid #4343ff;
    }
  }

  &__progress {
    gap: 5px !important;
    align-items: center;

    &.likely-ai span.ant-progress-text {
      color: #ff4d4f !important;
    }

    &.potentially-ai span.ant-progress-text {
      color: #efe384 !important;
    }
    &.not-ai span.ant-progress-text {
      color: #4343ff !important;
    }
  }

  &__loading {
    grid-template-columns: 1fr 0.5fr;
  }
}
.ant-collapse-header {
  padding-left: 0 !important;
}

@import "../../../resources/sass/variables.scss";
@import "../../../resources/sass/breakpoints.scss";
.login-container {
  padding-top: 40px !important;
  p {
    color: #2b3447;
  }
  .ant-form-item-control-input-content {
    display: flex;
  }
  button {
    min-width: 100px;
    margin: auto;
    margin-top: 10px;
    height: 40px;
    border-radius: 30px;
    span {
      font-family: $font-medium;
    }
  }
  button.button-terms {
    min-width: 150px !important;
    // padding: 10px 20px;
  }

  .btn-outlined {
    border-radius: 30px;
    border: 2px solid $telefonica-blue;
    span {
      color: $telefonica-blue;
    }
    // margin-top: 0 !important;
  }
}

.button-outlined {
  border: 1.5px solid #4343ff;
  border-radius: 30px;
  background-color: transparent;
  color: #4343ff;
}

.ant-btn-primary {
  background-color: #4343ff;
}

:where(.css-dev-only-do-not-override-nllxry).ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: #4343ff;
  border-color: #4343ff;
}

.ant-col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

//For result page

.ant-result-title {
  font-weight: bold !important;
  font-family: "Telefonica-Bold";
}

.ant-result-subtitle {
  margin-top: 15px;
  font-size: 1.1rem !important;
}

.ant-collapse-header {
  background: white;
}
.ant-collapse {
  border: none;
}
.ant-collapse-expand-icon {
  position: absolute;
  right: 10px;
}

:where(.css-dev-only-do-not-override-nllxry).ant-collapse
  .ant-collapse-content {
  border-top: none !important;
}

:where(.css-dev-only-do-not-override-nllxry).ant-switch.ant-switch-checked {
  background: $telefonica-blue;
}

//Modal confirm

.ant-modal-confirm-btns {
  margin-top: 20px !important;
}

:where(.css-dev-only-do-not-override-nllxry).ant-btn-default.ant-btn-dangerous {
  color: white;
  background-color: #4343ff !important;
  border-color: #4343ff !important;
  &:hover {
    color: inherit !important;
  }
}

:where(.css-nllxry).ant-checkbox-checked .ant-checkbox-inner {
  background-color: #4343ff !important;
  border-color: #4343ff !important;
  &:hover {
    color: inherit !important;
  }
}

.ant-message {
  z-index: 100000;
}

.ant-message-notice-content {
  padding: 20px !important;
  background: #e2e4ec !important;
}

.ant-message-custom-content {
  display: flex;
}

.ant-message-notice-wrapper {
  margin-top: 70px;
}

.ant-upload-list-item {
  border-color: transparent !important;
}

.ant-collapse-expand-icon {
  display: none !important;
}

@import "../../../resources/sass/variables.scss";
@import "../../../resources/sass/breakpoints.scss";

:where(.css-dev-only-do-not-override-nllxry).ant-dropdown .ant-dropdown-menu {
  .ant-dropdown-menu-item-selected {
    background-color: white;
    :hover {
      background: white;
    }
  }

  .ant-dropdown-menu-item {
    &:hover {
      background-color: white;
    }
  }

  .ant-dropdown-menu-title-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.FilterSelect {
  button {
    background: $telefonica-blue;
    border-radius: 30px;
    // height: 40px;
  }
}

@import "../../../resources/sass/variables.scss";
@import "../../../resources/sass/breakpoints.scss";
.MyReportDetail {
  padding-left: $spacing-base;

  padding-right: $spacing-base;
  background-color: #e2e4ec;
  border-radius: 20px;

  p {
    color: black;
  }

  small {
    color: #3232b9;
  }
}

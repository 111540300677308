@font-face {
  font-family: "Telefonica-Light";
  font-style: normal;
  font-weight: 300;
  src: url("../../../src/resources/fonts/Telefonica\ Sans\ Light.otf");
}
@font-face {
  font-family: "Telefonica-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../../../src/resources/fonts/Telefonica\ Sans\ Regular.otf");
}
@font-face {
  font-family: "Telefonica-Medium";
  font-style: normal;
  font-weight: 500;
  src: url("../../../src/resources/fonts/Telefonica\ Sans\ Medium.otf");
}
@font-face {
  font-family: "Telefonica-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../../../src/resources/fonts/Telefonica\ Sans\ Bold.otf");
}

@import "../../../resources/sass/breakpoints.scss";
@import "../../../resources/sass/variables.scss";

.banner {
  position: relative;
  z-index: 1;
  padding-top: 100px !important;
  @include media-breakpoint-up(md) {
    padding: 60px 0;
    // padding-bottom: 150px !important;
    padding-bottom: 10vw !important;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      // background-image: url("../../../../public/images/main/background.gif");
      background-position-y: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      filter: brightness(0.6);
      z-index: -1;
      display: block;
    }
  }

  @include media-breakpoint-down(md) {
    background-color: white;
    padding: 0;
  }

  h1 {
    margin-bottom: 0;
  }

  img {
    max-width: 100%;
    height: auto;

    &.img-white {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
    &.img-full {
      display: none;
      text-align: center;
      @include media-breakpoint-down(md) {
        display: block;
        margin: auto;
        padding: 15px 10px;
      }
    }
  }

  &__subtitle {
    font-size: 1.9rem;
    line-height: 40px;
    width: 55%;
    @include media-breakpoint-down(md) {
      width: 100%;
      text-align: center;
      color: $primary-color;
      font-size: 1.7rem !important;
      font-family: $font-bold;
    }
  }
}

@import "../../../../../resources/sass/variables.scss";
@import "../../../../../resources/sass/breakpoints.scss";
.MyReportDetailBody {
  background-color: white;
  // height: 75vh;
  padding: $spacing-base * 1.5;
  .border-gray {
    border-bottom: 0.5px solid #c1c1c1;
  }

  &__header {
    h1 {
      color: $primary-color;
      font-size: 1.3rem;
      padding-bottom: 0;
      margin-bottom: 5px;
      // font-weight: bold;
      // font-family: $font-bold;
    }
  }

  &__body {
    gap: 30px;
    font-size: 0.8rem;
    p {
      min-width: 150px;
      color: #4343ff !important;
      font-family: $font-semibold;
    }
    &__img-preview,
    &__video-preview {
      width: 300px;
      height: 200px;
      object-fit: contain;
    }
    &.placeholder {
      img {
        width: 300px;
      }
    }
  }
  &__footer {
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 0.4fr;
    font-size: 0.8rem;
    align-items: center;
  }
  .ant-tag {
    color: #4343ff;
    font-family: $font-semibold;
    border: none;
    font-size: 0.9rem;
  }
}

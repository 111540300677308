@import "../../../resources/sass/variables.scss";
@import "../../../resources/sass/breakpoints.scss";
.Navigator {
  background: $background-color;
  padding: 5px calc(10vw - $spacing-base);
  color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  &__body {
    display: flex;
    align-items: center;
    margin-left: -2.5rem;
  }
  h2 {
    color: white;
  }
  > * div {
    display: flex;
  }

  &:hover {
    cursor: pointer;
  }

  svg > path {
    fill: white;
  }

  &__content {
    p:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: 30px;
    padding-bottom: 30px;
    h3 {
      margin-left: 2rem !important;
      font-size: 2rem !important;
      font-family: $font-bold;
    }

    .icon {
      display: none;
    }
  }
}

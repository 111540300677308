@import "../../../resources/sass/breakpoints.scss";
@import "../../../resources/sass/variables.scss";

.header {
  background-color: #ffffff;
  position: fixed;
  z-index: 10000;
  max-width: 100vw !important;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 64px;
  padding: $spacing-base $spacing-base * 5 !important;

  border-top: 1px solid #dddddd;

  @include media-breakpoint-down(md) {
    padding-left: 15px !important;
    padding-right: 15px !important;
    flex-direction: column-reverse;
    height: 120px;
    justify-content: start;
    &__logo {
      display: block;
      img {
        width: 200px;
      }
    }
  }

  ul {
    background-color: transparent;
    border: none;
    text-align: center;
    // margin-left: auto;
  }
  &__menu {
    display: block;
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
  &__mobile {
    &-menu {
      display: none;
      @include media-breakpoint-down(md) {
        display: flex;
        width: 100%;
        padding: $spacing-base/2;
        justify-content: space-between;
      }
    }
  }

  &__cta {
    display: flex;
    align-items: center;
    gap: 20px;
    @include media-breakpoint-down(md) {
      display: none;
    }
    &-button {
      background-color: #0096eb;
      border: 1px solid #0096eb;

      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      border-radius: 40px;
    }
  }
  &__menu-selected {
    color: #0096eb;
  }

  .anticon {
    color: $primary-color !important;
  }
}

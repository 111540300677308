@import "../../../resources/sass/variables.scss";
@import "../../../resources/sass/breakpoints.scss";

.header {
  @include media-breakpoint-down(md) {
    height: initial !important;
    &__logo {
      display: none;
    }
  }
}

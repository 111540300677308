.UploadMultipleFile {
  .ant-upload {
    background-color: white;
  }

  .ant-upload-empty {
    .ant-upload {
      width: 100% !important;
      padding: 25px auto;
      flex-direction: column;
      height: 200px !important;
    }
    p {
      color: #d1d5e4;
    }
    svg {
      margin-top: 5px;
    }
  }
}

.TMyReportDetailSummary {
  &__body {
    display: grid;
    grid-template-columns: 1fr 0.6fr;
    gap: 20px;
    small {
      font-size: 0.75rem;
    }
    &-rating {
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px;
      justify-content: space-evenly;
      .box {
        padding: 10px 15px;
        .icon-medium {
          display: flex;
          margin: auto;
          svg {
            display: flex;
          }
        }
      }
    }
  }
  &__body li {
    font-size: 0.8rem;
  }

  &__body p {
    margin: 1rem 0;
    font-size: 0.875rem;
  }
  &__legend {
    list-style: none;
    display: flex;
    gap: 10px;
  }
  .ResultItem__rating {
    padding: 10px 14px;
    background: #e2e4ec;
    text-align: center;
    p {
      color: #2b3447;
    }
    svg {
      fill: #9ea8be;
    }
    &-selected svg {
      fill: #4343ff !important;
    }
    button {
      border: 2px solid #d1d5e4;
      border-radius: 17px;
      margin-right: 3px;
      color: #2b3447bf;
    }
  }
}

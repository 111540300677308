@import "../../../resources/sass/breakpoints.scss";
@import "../../../resources/sass/variables.scss";

.result-page {
  padding-bottom: 2rem !important;
  @include media-breakpoint-down(md) {
    padding: $spacing-base calc(12vw - $spacing-base);
  }

  .carousel {
    padding: 0px;
  }

  .Navigator {
    padding: 0;
    padding-top: 2rem;
    margin: 0;
    background: none;
  }

  .result-antd > div {
    color: white;
  }
  button.btn-more {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}

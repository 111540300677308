/*  Spacing  */
$border-radius: 12px;
$spacing-base: 25px;

// $max-width: 1050px;
$max-width: 900px;
/*  Colors  */
$green-color: #00dabd;
$green-color-1: #3dd8ad;
$green-color-1-1: #3dd8ad80;
$green-color-2: #3dd9ad;
$green-color-3: #28eb9c;
$blue-color: #38adef;
$red-color: #ff0000;
$black-color: black;

$title-color: #101726;
$body-bg-color: #fafdff;
$white-color: #ffffff;
$green-color-4: #00dabd;
$green-color-5: #00f2a4;
$lightblue-color: #009bf8;
$white-color-1: #f5f5f5;

/*Main colors */
$primary-color: #041e55;
$gray-color: #495057;
$secondary-color: #00795b;
$primary-color-alt: #ffc42e;
$text-color: white;

/*New Tu.com colors*/
$telefonica-black: rgba(32, 39, 53, 1);
$telefonica-blue: #4343ff;

/*Background colors*/
$background-color: #222280;

/*  Font Family */
$font-medium: "Telefonica-Medium", sans-serif;
$font-bold: "Telefonica-Bold", sans-serif;
$font-semibold: "Telefonica-SemiBold", sans-serif;
$font-regular: "Telefonica-Regular", sans-serif;
$font-light: "Telefonica-Light", sans-serif;
$font-italic: "Telefonica-Italic", sans-serif;

/*  Font Size  */
$header-height: 4rem;
$navbar-height: 4rem;
$section-spacing: 4rem;
$block-spacing: 2rem;
$body-line-height: 1.6;
$title-line-height: 1.3;

$h1-font-size: 2.5rem;
$h2-font-size: 1.7rem;
$h3-font-size: 1.6rem;
$p-font-size: 1.15rem;
$h4-font-size: 1rem;
$big-font-size: 1rem;
$normal-font-size: 0.875rem;
$small-font-size: 0.85rem;
$smaller-font-size: 0.6875rem;

/* Layouts */
$z-back: -10;
$z-normal: 1;
$z-front: 10;
$z-tooltip: 20;
$z-overlay: 30;
$z-modal: 40;
$z-fixed: 50;
$z-off-canvas: 100;

/* others */
$border-radius: 0.5rem;
$box-shadow: 0 3px 6px 0 rgb(0 0 0 / 20%);

$box-shadow-2x: 0 1px 12px rgba(0, 0, 0, 0.125);
$box-shadow-3x: 0 1px 24px rgba(0, 0, 0, 0.25);

$nav-height: 76px;
$nav-height-mobile: 50px;

$small-dimensions: 36px;

$transition: all 0.42s ease;
$transition-low: all 1s ease;
$transition-fast: all 0.21s ease;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

@media screen and (min-width: 1024px) {
  $header-height: 4rem !global;
  $section-spacing: 6rem !global;
  $block-spacing: 4rem !global;
  $h1-font-size: 2.5rem !global;
  $h2-font-size: 2rem !global;
  $h3-font-size: 1.5rem !global;
  $h4-font-size: 1.2rem !global;
  $big-font-size: 1.25rem !global;
  $normal-font-size: 1rem !global;
  $small-font-size: 1rem !global;
  $smaller-font-size: 0.75rem !global;
}

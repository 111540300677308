.max-width > * {
  color: black;
}

.wp-block-heading {
  margin-top: 20px;
}

.ul-cookies {
  margin-right: 20px;
}

.TAudio {
  .icon-large svg {
    fill: #4343ff;
  }

  &__actions {
    display: flex;
    gap: 20px;
    margin: auto;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}

@import "../../../../../resources/sass/variables.scss";
@import "../../../../../resources/sass/breakpoints.scss";
.TMyReportPrint__modal {
  .ant-modal-title {
    font-family: $font-bold;
    font-size: 1.2rem;
  }

  p {
    color: black;
  }

  .TMyReportPrint__body {
    &-item {
      display: grid;
      grid-template-columns: 0.5fr 0.3fr 0.1fr;
    }
  }
}

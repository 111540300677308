@import "../../../resources/sass/variables.scss";
@import "../../../resources/sass/breakpoints.scss";
.Content {
  padding: $spacing-base $spacing-base * 1.5;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 30px;
  border-radius: 16px;
  background: #fff;
  p {
    color: $gray-color;
    font-size: 0.9rem;
  }

  .img-multiple {
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    margin-top: 5px;
  }
  h5 {
    color: #041e55;
    font-size: 1rem;
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: auto;
    padding: $spacing-base;
  }
}

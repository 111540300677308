.TMyReportTitleItem {
  color: #071229;
  padding: 0;
  &.withBackground {
    background-color: #e2e4ec;
  }
  p {
    font-size: 1.4rem;
    color: black !important;
    border-bottom: 1px solid #d1d5e4;
  }
}

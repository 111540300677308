@import "../../../resources/sass/breakpoints.scss";
@import "../../../resources/sass/variables.scss";

.login-page {
  padding-top: 40px;
  background-color: #161a24;
  height: 97vh;
}

body {
  // background-color: $telefonica-black !important;
}

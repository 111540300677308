@import "../../../resources/sass/variables.scss";
@import "../../../resources/sass/breakpoints.scss";
.help-page {
  background-color: #f2f5f8;

  h4 {
    color: #041e55;
  }

  .Navigator__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @include media-breakpoint-down(md) {
      grid-template-columns: auto;
      padding-left: $spacing-base;
    }
  }

  @include media-breakpoint-down(md) {
    background-color: white;

    h4 {
      font-size: 1.5rem !important;
      // text-align: center;
      padding-left: $spacing-base;
      margin-bottom: 0;
      width: 75%;
    }
  }

  .help_content {
    padding-bottom: $spacing-base;
  }
}

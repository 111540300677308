@import "../../resources/sass/breakpoints.scss";

.scroll {
  position: fixed;
  right: 5%;
  bottom: 5%;
}

.terms__condition {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
}
@include media-breakpoint-up(md) {
  .scroll {
    display: none !important;
  }
}

@import "../../../resources/sass/variables.scss";
@import "../../../resources/sass/breakpoints.scss";
.MyReport {
  padding-left: $spacing-base;

  padding-right: $spacing-base;
  background-color: #e2e4ec;
  border-radius: 16px;

  .dahsed-button {
    background-color: transparent;
  }

  p {
    color: #041e55;
  }

  .icon_device path,
  .icon_device svg {
    padding-top: 3px;
    stroke: #4343ff;
  }

  .report_progress {
    margin: auto;
    display: flex;
    justify-content: center;
    .ant-progress-text {
      display: none;
    }
    .ant-progress-circle-gradient {
      width: 28px !important;
      height: 28px !important;
    }
  }

  .ant-table-thead {
    tr > td,
    tr > th {
      background-color: white;
      font-size: 0.75rem;
      font-family: $font-semibold;
      border-bottom: none !important;
      text-align: center;
      &:before {
        background-color: white !important;
      }
    }
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    padding: 12px 8px !important;
    text-align: center;
  }

  &__filters {
    display: flex;
    button {
      background-color: #041e55;
    }
  }

  .TChart canvas {
    width: 70px !important;
    height: 70px !important;
  }
  .ant-checkbox-inner {
    border-color: #4343ff;
    background-color: #f2f4ff;
  }

  .ant-table-cell {
    .ant-tag {
      color: #4343ff;
      font-family: $font-semibold;
      border: none;
    }
    .ant-result {
      display: flex;
      gap: 5px;
      path,
      svg {
        padding-top: 3px;
      }
    }
  }
}

.CarouselImage {
  p {
    font-size: 1.2rem;
  }
  &-container {
    margin-top: 10px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(8, auto);
  }
  img {
    width: 150px;
    height: auto;
  }
}

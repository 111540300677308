@import "antd";

$max-width-print-pdf: 500px !important;

.MyReportPDF {
  width: $max-width-print-pdf;
  border: 2px solid red;

  * {
    color: #041e55;
  }
}

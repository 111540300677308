@import "../../../resources/sass/variables.scss";
@import "../../../resources/sass/breakpoints.scss";

.LanguageSelector {
  display: flex;
  // justify-content: flex-end;
  // margin: 5px calc(10vw - $spacing-base);
  p {
    color: $primary-color;
    font-size: 14px;
    font-weight: lighter;
    &.selected {
      font-family: $font-medium;
    }
    &:hover {
      cursor: pointer;
    }
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}
